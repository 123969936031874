import './globals/jquery'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/js/dist/carousel'

import 'jquery-ui/ui/version'
import 'jquery-ui/ui/keycode'
import 'jquery-ui/ui/widgets/datepicker'

import '#/layouts/js'
import '#/pages/js'
import '#/activities/js'
import '#/academics/js'
