export default class Slider {
  constructor(el) {
    this.element = $(el)
    this.slides = this.element.find('.sliderItem')
    this.current = this.slides.first()
    this.timer = null
    this.moving = false

    this.element.css({ 'position': 'relative' })
    this.element.find('.sliderItem:gt(0)').hide()

    this.element.prepend(`
      <div class="sliderControls">
        <span class="previous"></span>
        <span class="play pause"></span>
        <span class="next"></span>
      </div>
    `)

    this.element.find('.mainImage').each((ix, el) => {
      var newPrev = this.prevSlide($(el).parent('.sliderItem')).find('.mainImage').clone()
      $(el).before(newPrev.removeClass('mainImage').addClass('prevImage'))

      var newNext = this.nextSlide($(el).parent('.sliderItem')).find('.mainImage').clone()
      $(el).after(newNext.removeClass('mainImage').addClass('nextImage'))
    })

    this.attachEventListeners()
    this.start()
  }

  start() {
    this.timer = setInterval(() => (this.animate('next', true)), 10000)
    this.element.find('.play.pause').removeClass('play')
  }

  stop() {
    clearInterval(this.timer)
    this.timer = null
    this.element.find('.pause').addClass('play')
  }

  playPause() {
    if (!this.timer)
      this.start()
    else
      this.stop()
  }

  nextSlide(slide) {
    var next = slide.next('.sliderItem')
    if (next.length == 0)
      next = this.slides.first()

    return next
  }

  prevSlide(slide) {
    var prev = slide.prev('.sliderItem')
    if (prev.length == 0)
      prev = this.slides.last()

    return prev
  }

  animate(direction, auto) {
    if (this.moving)
      return true

    if (!auto)
      this.stop()

    var position = this.current.offset().left
    var newSlide, travel

    if (direction == 'next') {
      newSlide = this.nextSlide(this.current)
      travel = newSlide.outerWidth()
    }
    else {
      newSlide = this.prevSlide(this.current)
      travel = -newSlide.outerWidth()
    }

    this.moving = true

    // Shift current slide away and make transparent
    this.current.css({
      position: 'absolute',
      top: 0,
      left: position
    }).animate({
      left: position - travel,
      opacity: 0
    }, 800)

    // Shift in current slide and make visible
    newSlide.show().css({
      position: 'absolute',
      top: 0,
      left: position + travel,
      opacity: 0
    }).animate({
      left: position,
      opacity: 1
    }, 800, () => {
      // Reset and save new slide as current
      this.current.removeAttr('style').hide()
      newSlide.removeAttr('style')
      this.moving = false
      this.current = newSlide
    })
  }

  attachEventListeners() {
    this.element.on('click', '.pause', (e) => {
      e.preventDefault()
      this.playPause()
    })

    this.element.on('click', '.next', (e) => {
      e.preventDefault()
      this.animate('next')
    })

    this.element.on('click', '.previous', (e) => {
      e.preventDefault()
      this.animate('prev')
    })
  }
}
