import 'cluetip'

export default function calendar(id) {
  var calendar = $(`#${id}`)
  if (calendar.length == 0)
    return

  var observer = new MutationObserver(function() {
    $('.event-hover').cluetip({
      splitTitle: '|',
      showTitle: true,
      leftOffset: 0,
      topOffset: 15,
      cursor: 'pointer'
    })
  })

  observer.observe(calendar[0], { childList: true, subtree: true })

  var specialDays = JSON.parse(calendar.attr('data-days'))

  calendar.datepicker({
    beforeShowDay: function(date) {
      var eventAt = date.toISOString().substr(0, 10)
      if (specialDays[eventAt])
        return [false, 'event event-hover', specialDays[eventAt]]

      return [false,'']
    }
  })
}
