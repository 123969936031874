var clipOffset, loading, lastClip

const loadClipBatch = function(el, offset) {
  var type = el.attr('id').match(/main(\w+)Div/)[1].toLowerCase()
  $.get(`/activities/${type}_clips?count=${offset}`, function(data) {
    var content = $(data)
    var resultsCount = content.filter('.title').length
    clipOffset += resultsCount

    if (resultsCount < 5)
      lastClip = true

    el.append(content)
    $('.loading').remove()
    loading = false
  })
}

const maxScroll = () => $(document).height() - $(window).height()

const clipScroll = function() {
  var el = $('#mainNewsDiv, #mainUpcomingDiv')
  if (el.length == 0 || loading || lastClip)
    return

  if ($(window).scrollTop() == maxScroll() || clipOffset == 0) {
    loading = true
    el.append('<div class="loading">Loading ...</div>')
    loadClipBatch(el, clipOffset)
  }
}

$(window).on('scroll', clipScroll)

$(function() {
  clipOffset = 0
  loading = false
  lastClip = false
  clipScroll()
})
