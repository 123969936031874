import '../css'

$(document).on('mouseenter', '.sim-label', function() {
  var panel = $(this).parent('.sim-panel')
  $('.sim-container .sim-bg').html(panel.children('.sim-bg-master').html()).addClass('active')
})

$(document).on('mouseleave', '.sim-label, .sim-menu', function() {
  var panel = $(this).parent('.sim-panel')
  if (panel.children('.sim-menu').is(':hover'))
    return

  $('.sim-container .sim-bg').removeClass('active')
})
